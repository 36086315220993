// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "chart.js";
import 'chartjs-adapter-moment';
import "@glidejs/glide";
// import "@ckeditor/ckeditor5-build-classic";
import "sortablejs";
import "animate.css";

import moment from 'moment';
window.moment = moment; // Optional: Making moment available globally
window.format_timestamp = (timestamp) => {
  return moment.utc(timestamp).format('DD-MM-YYYY HH:mm UTC')
};

window.format_date = (date) => {
  return moment.utc(date).format('DD-MM-YYYY UTC')
};

require("jquery");
// require("packs/customs/parameterSettings.js");
// require("packs/customs/currencyRate.js");
// require("packs/customs/customerCharge.js");
require("packs/customs/custom.js");
require("packs/customs/numberFormat.js");
Rails.start();
Turbolinks.start();
ActiveStorage.start();
document.addEventListener('turbolinks:load', () => {
  const notificationIcon = document.getElementById('notification-icon');

  if (notificationIcon) {
    notificationIcon.addEventListener('click', () => {
      // Handle click on the notification icon (e.g., show a list of notifications)
      console.log('Notification icon clicked!');
    });
  }
});

let activityTimeout;
var shouldKeepAlive = false;
let inputTimeout;

function shouldNotKeepSessionAlive() {
  shouldKeepAlive = false;
}

function sessionPopup() {
  // 1+4 = 5 minutes
  if (shouldKeepAlive) {
    $.ajax({
      url: "/hello_world",
      type: "GET",
      dataType: "json",
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (response) {
        resetActivityTimeout();
      },
    });
    return;
  }

  alert('User is idle for last 5 minutes, session will expire in another 15 minutes');
  // don't reload on create pages to preserve data
  const regex = /\/new/;
  if (!window.location.href.match(regex)) window.location.reload();
}

function resetActivityTimeout() {
  clearTimeout(activityTimeout);
  const regex = /bo_users\/sign_in|agent_users\/sign_in/;
  if (!window.location.href.match(regex)) {
    activityTimeout = setTimeout(sessionPopup, 300000); // 5 minutes in milliseconds
  }
}

function resetInputTimeout() {
  clearTimeout(inputTimeout);
  shouldKeepAlive = true;
  inputTimeout = setTimeout(shouldNotKeepSessionAlive, 10000); // wait for 10 seconds between input state reset
}

// Store the original XMLHttpRequest object
var originalXHROpen = window.XMLHttpRequest.prototype.open;

// Overwrite the open method to add a custom callback
window.XMLHttpRequest.prototype.open = function (method, url) {
  // console.log('Ajax request is about to be made.');
  // console.log('Method:', method);
  // console.log('URL:', url);

  // Call the original open method
  originalXHROpen.apply(this, arguments);
  resetActivityTimeout();
};

resetActivityTimeout();


$(function () {
  $(document).on('click', '.custom-select-icon', function () {
    // console.log($(this).prev());
    const selectEl = $(this).prev()[0];
    if (!!selectEl && "showPicker" in HTMLSelectElement.prototype) {
      selectEl.showPicker();
    }
  });

  $(document).on("keyup change", "input, select, textarea", function () {
    resetInputTimeout();
  });
})
